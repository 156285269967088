import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #000;
  color: #fff;
  padding: 20px;
  text-align: center;
`;

const SocialLinks = styled.div`
  margin-bottom: 10px;

  a {
    color: #ff0000;
    margin: 0 10px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

// Set this to true once you're ready to display the social links
const showSocialLinks = false;

const Footer = () => {
  return (
    <FooterContainer>
      {showSocialLinks && (
        <SocialLinks>
          <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer">
            Twitter
          </a>
          <a href="https://facebook.com/yourprofile" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
          <a href="https://instagram.com/yourprofile" target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
        </SocialLinks>
      )}
      <p>&copy; {new Date().getFullYear()} Beau Bennet. All rights reserved.</p>
    </FooterContainer>
  );
};

export default Footer;
