// src/components/SignupForm.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaInstagram } from 'react-icons/fa'; // Import the Instagram icon
import axios from 'axios';

const FormContainer = styled.div`
  background-color: #111;
  padding: 30px;
  max-width: 400px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: column; // Arrange links vertically
  align-items: center; // Center align items
  margin-top: 20px; // Add margin for spacing

  a {
    display: flex; // Use flex to align icon and text
    align-items: center; // Center vertically
    color: #ff0000;
    margin: 5px 0; // Add vertical margin for spacing
    text-decoration: none;
    font-size: 1.2em;

    &:hover {
      text-decoration: underline;
      color: #e60000; // Change color on hover
    }

    svg {
      margin-right: 8px; // Add spacing between icon and text
      font-size: 1.5em; // Adjust icon size
    }
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #222;
  color: #fff;
  font-size: 1em;

  &:focus {
    border-color: #fff;
    outline: none;
  }
`;

const Button = styled.button`
  width: 100%;
  background-color: #ff0000;
  color: white;
  padding: 14px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;

  &:hover {
    background-color: #e60000;
  }
`;

const Message = styled.p`
  color: ${(props) => (props.error ? '#ff4d4d' : '#4dff4d')};
  text-align: center;
  margin-top: 10px;
`;

const SignupForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic email validation
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      setError(true);
      setMessage('Please enter a valid email address.');
      return;
    }

    try {
      // Use relative path instead of absolute URL
      const response = await axios.post('/subscribe', { email });

      setError(false);
      setMessage(response.data.message);
      setEmail('');
    } catch (err) {
      console.error('Subscription Error:', err.response ? err.response.data : err.message);
      setError(true);
      setMessage(err.response && err.response.data.error ? err.response.data.error : 'There was an error signing up. Please try again.');
    }
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit} aria-label="Email Signup Form">
        <label htmlFor="email" style={{ display: 'none' }}>
          Email Address
        </label>
        <Input
          id="email"
          type="email"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          aria-required="true"
        />
        <Button type="submit">Sign Up</Button>
      </form>
      {message && <Message error={error}>{message}</Message>}
      <SocialLinks>
        <h3 style={{ marginBottom: '10px', color: '#fff' }}>Follow me on social media</h3>
        <a href="https://www.instagram.com/beaubennetwrites/" target="_blank" rel="noopener noreferrer">
          <FaInstagram /> Instagram
        </a>
      </SocialLinks>
    </FormContainer>
  );
};

export default SignupForm;