import React from 'react';
import Banner from './components/Banner';
import SignupForm from './components/SignupForm';
import Footer from './components/Footer';
import GlobalStyle from './globalStyles';
import styled from 'styled-components';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Container = styled.main`
  flex: 1; /* Takes up remaining space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
`;

function App() {
  return (
    <AppWrapper>
      <GlobalStyle />
      <Container>
        <Banner />
        <SignupForm />
      </Container>
      <Footer />
    </AppWrapper>
  );
}

export default App;
