import React from 'react';
import styled from 'styled-components';

const BannerContainer = styled.div`
  background-color: #000;
  color: #ff0000;
  padding: 30px 20px;
  text-align: center;
  max-width: 800px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.7);

  @media (max-width: 600px) {
    padding: 20px 10px;
  }
`;


const BannerText = styled.h1`
  font-size: 2em;
  margin: 0;
  text-shadow: 2px 2px 4px #ff0000;
  animation: flicker 4.0s infinite;

  @keyframes flicker {
    0% { opacity: 1; }
    50% { opacity: 0.8; }
    100% { opacity: 1; }
  }
`;

const SubText = styled.p`
  font-size: 1.2em;
  margin-top: 10px;
  color: #ff4d4d;
`;

const Banner = () => {
  return (
    <BannerContainer>
      <BannerText>Beau Bennet - Horror Writer</BannerText>
      <SubText>
        Sign up for the email list below to be the first to know about news, upcoming work, and special insider information.
      </SubText>
    </BannerContainer>
  );
};

export default Banner;
